<template>
  <router-link
    tag="div"
    class="menu_element_block flex align-middle"
    :to="'/' + path"
  >
    <span class="avtive_position" />
    <div class="icon menu_element_icon w-10 h-10 rounded-full">
      <svg-icon
        :name="icon"
        class=" h-full w-full"
        original
      />
    </div>
    <div class="menu_element ml-8">
      {{ menu }} <span v-if="isWallet">(<span class="walletAmount text-sm font-bold">{{ getWalletBalance }}€</span>)</span>
      <span
        v-if="isNotif && hasUread"
        class="ml-2"
      >
        <svg-icon
          name="ic-notif-green"
          class="w-2 h-2 -mt-3"
          original
        />
      </span>
    </div>
  </router-link>
</template>

<script>
import firebase from 'firebase'
import { firebaseDb } from '../../main'
import { firebaseRef } from '../../rema-function/firebaseRef'

export default {
  name: '',
  props: {
    menu: String,
    icon: String,
    path: String,
    isWallet: {
      type: Boolean,
      default: false
    },
    isNotif: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      wallet: {},
      allConversations: []
    }
  },
  firebase () {
    return {
      wallet: firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid),
      allConversations: firebaseDb.ref(firebaseRef.conversationRef + firebase.auth().currentUser.uid)
    }
  },
  computed: {
    conversationsFiltered: function () {
      let filterKey = ''
      // filterKey = this.filterKey.trim()
      let conversations = this.allConversations
      if (firebase.auth().currentUser.uid) {
        var second
        let self = this
        if (filterKey && this.allConversations) {
          conversations = conversations.filter(function (row) {
            for (let i = 0; i < row.participants.length; i++) {
              if (row.participants[i].uid === self.user.uid) {
                second = Math.abs(i - 1)
                return String(row.participants[second].fullname).toLowerCase().indexOf(filterKey.toLowerCase()) > -1
              }
            }
          })
        }
      }
      return conversations
    },
    hasUread () {
      let unRead = false
      for (let i = 0; i < this.conversationsFiltered.length; i++) {
        if (this.interlocuteurIsSender(this.conversationsFiltered[i]) && !this.conversationsFiltered[i].lastmsg.seen) {
          unRead = true
        }
      }
      return unRead
    },
    getWalletBalance: function () {
      if (typeof this.wallet.balance !== 'undefined') {
        return Number.parseFloat(this.wallet.balance.toString()).toFixed(1)
      } else {
        return 0
      }
    }
  },
  created () {
    setInterval(() => {
      let data = []
      firebaseDb.ref(firebaseRef.conversationRef + firebase.auth().currentUser.uid)
        .once('value', function (snapshot) {
          snapshot.forEach(function (childSnapshot) {
            data.push(childSnapshot.val())
          })
        })
      this.allConversations = data
      console.log(data)
    }, 1000)
  },
  methods: {
    interlocuteurIsSender: function (interlocuteur) {
      // return (this.interlocuteur.lastmsg.from === this.currentUserUid)
      // alert(firebase.auth().currentUser.uid)
      // alert(firebase.auth().currentUser.uid)
      return !(interlocuteur.lastmsg.from === firebase.auth().currentUser.uid)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/style/sass/mixin';
.menu_element_block{
  padding-left: 4rem;
  padding-top: .70rem;
  padding-bottom: .70rem;
  padding-right:  0rem;
  margin: .1rem 0;
  position: relative;
  color: $Black-Rema-text;
  font-weight: 300;
  cursor: pointer;
  .avtive_position{
    position: absolute;
    left: 0;
    top: 22%;
    height: 50%;
    width: 3px;
    border-radius: 0 50px 50px 0;
    visibility: hidden;
  }
  .walletAmount {
   color: $Green-Rema;
  }
  .menu_element{
    padding-top: .8rem;
    font-size: 1.2rem;
  }
  .menu_element_icon {
    background-color: $Rema-Icon-Background;
    padding: .5rem;
  }
  &:hover{
    .avtive_position{
      visibility: visible;
      background-color: $Green-Rema;
    }
  }
  &.router-link-active{
    color: $Black-Rema-text;
    font-weight: bold;
    .avtive_position{
      visibility: hidden;
      background: $Green-Rema;
    }
    .menu_element_icon{
      background-color: $Green-Rema;
      path{
        fill: white!important;
      }
    }
  }
}
</style>
