<template>
  <div class="home_page">
    <!--<div class="drawer">
      <CardCreation></CardCreation>
    </div> !-->
    <the-header :showOptionList="optionList" @optionStateChange="optionStateChange"/>
    <div class="content_body flex" >
      <div class="nav_sidebar">
        <the-sidebar />
      </div>
      <section class="main_content ">
        <router-view class="viewer container m-auto py-10 px-8"  @click="hideOption"/>
      </section>
    </div>
  </div>
</template>

<script>
import theHeader from '@/components/the-header'
import theSidebar from '@/components/the-sidebar'

export default {
  name: 'Home',
  components: {
    theHeader,
    theSidebar
  },
  data () {
    return {
      optionList: false
    }
  },
  methods: {
    hideOption () {
      this.optionList = false
    },
    optionStateChange (answer) {
      if (answer) {
        this.optionList = answer
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/style/sass/variables";
.home_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  height: 100%;
  .nav_sidebar {
    // height: calc(100% - 5rem);
  }
  .content_body {
    width: 100%;
    height: calc(100% - 5rem);
    overflow-y: hidden;
    .main_content {
      height: 100%;
      overflow: scroll;
      width: 100%;
      background: $Rema-Content-Background;
      .viewer{
        height: 100%;
      }
    }
  }
}
</style>
